import React from 'react'
import ReactMarkdown from 'react-markdown'
import { stepTextStyles } from './styles'

const StepText: React.FC = ({
  children,
}) => {
  const classes = stepTextStyles()

  if (typeof children !== 'string') {
    return null
  }

  return (
    <div className={classes.root}>
      <ReactMarkdown
        renderers={{
          paragraph: ({ children }) => {
            return (
              <div className={classes.p}>{children}</div>
            )
          },
        }}
      >
        {children}
      </ReactMarkdown>
    </div>
  )
}

export default StepText