import useTranslation from 'next-translate/useTranslation'

import { I_USE_TRANSLATE } from './types'


export const useTranslate: I_USE_TRANSLATE = (ns = 'main') => {
  return useTranslation(ns)
}


export default useTranslate