import React from 'react'

import { I_STEP_IMAGE_PROPS } from './types'

import { stepImageStyles } from './styles'

import NextImg from 'next/image'


const types = {
  duck: require('../../../../public/img/duck.png'),
  ball: require('../../../../public/img/ball.png'),
}


const StepImage: React.FC<I_STEP_IMAGE_PROPS> = ({
  type,
}) => {
  const classes = stepImageStyles()

  return (
    <div className={classes.root}>
      <div className={classes.img}>
        <NextImg
          src={types[type]}
          alt={type}
          layout='fill'
          objectFit='contain'
          loading='eager'
          priority
        />
      </div>
    </div>
  )
}

export default StepImage