import { makeStyles } from '@material-ui/core'

export const stepTextStyles = makeStyles(theme => ({
  root: {
    [theme.breakpoints.down('xl')]: {
      marginBottom: '2.5rem',
    },
    [theme.breakpoints.down('lg')]: {
      marginBottom: '2rem',
    },
    [theme.breakpoints.down('md')]: {
      marginBottom: '1.5rem',
    },
    [theme.breakpoints.down('sm')]: {
      marginBottom: '1.25rem',
    },
    [theme.breakpoints.down('xs')]: {
      marginBottom: '1rem',
    },
  },
  p: {
    lineHeight: 1.6,
    marginBottom: '1rem',
    [theme.breakpoints.down('xl')]: {
      fontSize: '1.25rem',
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '1rem',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '1rem',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '.875rem',
    },
  },
}))