import React from 'react'
import { stepTitleStyles } from './styles'

const StepTitle: React.FC = ({
  children,
}) => {
  const classes = stepTitleStyles()

  return (
    <div className={classes.root}>
      <h1 className={classes.title}>
        {children}
      </h1>
    </div>
  )
}

export default StepTitle
