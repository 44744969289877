import { makeStyles } from '@material-ui/core'

export const stepImageStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    [theme.breakpoints.down('sm')]: {
      marginBottom: '3.75rem',
    },
    [theme.breakpoints.down('xs')]: {
      marginBottom: '1.875rem',
    },
  },
  img: {
    position: 'relative',
    [theme.breakpoints.down('xl')]: {
      maxWidth: 400,
      height: 528,
    },
    [theme.breakpoints.down('lg')]: {
      maxWidth: 360,
    },
    [theme.breakpoints.down('md')]: {
      maxWidth: 260,
      height: 432,
    },
    [theme.breakpoints.down('sm')]: {
      maxWidth: 260,
      height: 312,
    },
    [theme.breakpoints.down('xs')]: {
      maxWidth: 120,
      height: 144,
    },
    width: '100%',
  },
}))