import { makeStyles } from '@material-ui/core'

export const stepTitleStyles = makeStyles(theme => ({
  root: {
    [theme.breakpoints.down('xl')]: {
      marginBottom: '2.5rem',
    },
    [theme.breakpoints.down('lg')]: {
      marginBottom: '2rem',
    },
    [theme.breakpoints.down('md')]: {
      marginBottom: '1.5rem',
    },
    [theme.breakpoints.down('sm')]: {
      marginBottom: '1.25rem',
    },
    [theme.breakpoints.down('xs')]: {
      marginBottom: '1rem',
    },
  },
  title: {
    margin: 0,
    [theme.breakpoints.down('xl')]: {
      fontSize: '4.5rem',
      lineHeight: 1.2,
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '2.75rem',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '2rem',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '1.5rem',
      lineHeight: 1.4,
    },
  },
}))